//any type checked blueice
import { Range } from '@ms/core/components/datepicker/date-picker.model';

export class ImportedDocument {
    constructor(
        public name: string,
        public type?: ImportedDocumentType,
        public importedDate?: Date,
        public entityId?: string,
        public description?: string
    ) {
        this.name = name ? name : '';
        this.type = type ? type : null;
        this.importedDate = importedDate ? importedDate : null;
        this.entityId = entityId ? entityId : null;
        this.description = description ?? null;
    }
}

export class ImportedDocumentSearchDTO {
    constructor(
        public name: string,
        public type?: ImportedDocumentType,
        public schoolId?: string,
        public dateRange?: Range,
        public pageSize?: number,
        public pageIndex?: number
    ) {}
}

export const ImportedDocumentType = {
    EMPLOYEE: 'EMPLOYEE',
    STUDENT: 'STUDENT',
    BUDGET_ACCOUNT: 'BUDGET_ACCOUNT',
    USER: 'USER',
} as const;
export type ImportedDocumentType = (typeof ImportedDocumentType)[keyof typeof ImportedDocumentType];
